import React from 'react';
import { makeStyles } from '@mui/styles';
// import { ReactComponent as Logo } from '../resources/images/logo.png';
import LogoNovo from '../resources/images/logo.png';

const useStyles = makeStyles(() => ({
  image: {
    margin: 'auto',
    marginTop: '8%',
    alignSelf: 'flex-start',
    maxWidth: '300px',
    maxHeight: '120px',
    width: 'auto',
    height: 'auto',
  },
}));

// const LogoImage = ({ color }) => {
const LogoImage = () => {
  const classes = useStyles();
  // return (<Logo className={classes.image} style={{ color }} />);
  return <img className={classes.image} src={LogoNovo} alt="" />;
};

export default LogoImage;
